import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

function Banner({ countryCode, countries, flexi, setFlexi }) {
  const [phone, setPhone] = useState("");
  const [goto, setGoto] = useState(false);
  const [provider, setProvider] = useState("");
  const [countryOperators, setCountryOperators] = useState("");
  const [Loaded, setLoaded] = useState(false);
  const [country, setCountry] = useState("");
  const [getting, setGetting] = useState(true);
  const [fixed, setFixed] = useState(null);

  useEffect(() => {
    setGetting(true);
    Axios.post(
      "https://ezetopmobile.com/opp/operator",
      { country: country },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.data !== "Null") {
          setCountryOperators(response.data);
          setGetting(false);
        } else {
          setCountryOperators(false);
          setGetting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setGetting(false);
      });
  }, [country]);

  useEffect(() => {
    setGetting(true);
    Axios.post(
      "https://ezetopmobile.com/opp/fixed",
      { code: provider && provider },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.data) {
          setFixed(response.data);
          setGetting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setGetting(false);
      });
  }, [provider]);

  useEffect(() => {
    setGetting(true);
    Axios.post(
      "https://ezetopmobile.com/opp/flexi",
      { code: provider && provider },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.data) {
          setFlexi(response.data);
          setGetting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setGetting(false);
      });
  }, [provider, setFlexi]);

  if (goto === true) {
    return (
      <Redirect
        to={{
          pathname: "/Products",
          state: {
            provider,
            phone,
            fixed,
            flexi,
          },
        }}
      />
    );
  }

  function ask() {
    swal({
      title: "Are you sure the Recipients phone number is correctly formatted?",
      text: "Once you Proceed, if recipients phone number or telco/Operator is wrong Recharge would fail!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        return handleRecharge();
      }
    });
  }

  const handleRecharge = async () => {
    if (phone) setLoaded(true);
    if (phone && provider && (flexi || fixed)) {
      setGoto(true);
    } else {
      swal("Please Check if you provided all the required Information");
    }
  };

  return (
    <Container className="banner" fluid>
      <Container>
        <Row>
          <Col md={6} className="ml-auto mr-auto pt-3 ">
            <h2 className="text-center homeHead">
              Worldwide mobile recharge: send
              <br /> credit and data to any phone
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={5} className="ml-auto mr-auto ">
            <div className="countrySelect">
              <Form>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Recipient's Phone Number:</Form.Label>
                  <PhoneInput
                    country={countryCode ? countryCode.toLowerCase() : "us"}
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    required
                  />

                  <Form.Text className="text-muted text-center px-2 mb-2">
                    "Phone number must be in International Format eg:
                    233240000000 without the beginning '0' or '00's"
                  </Form.Text>
                  <Form.Group>
                    <Form.Label>Choose Recipient's Country:</Form.Label>
                    <Form.Control
                      as="select"
                      size="sm"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <option>Choose...</option>
                      {countries &&
                        countries?.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustom">
                  {getting ? (
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        className="mt-1 spiColor align-self-center"
                      />
                    </div>
                  ) : (
                    <div>
                      <Form.Label>
                        Choose Recipient's Telco(Operator):
                      </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        onChange={(e) => setProvider(e.target.value)}
                        value={provider}
                      >
                        <option>Choose...</option>
                        {countryOperators &&
                          countryOperators?.map((operator, index) => {
                            return (
                              <option key={index} value={operator?.code}>
                                {operator?.operator}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </div>
                  )}
                </Form.Group>

                {provider ? (
                  <Button
                    block
                    variant="success"
                    className="reButton"
                    onClick={ask}
                  >
                    {Loaded ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      "Start Recharge"
                    )}
                  </Button>
                ) : (
                  <Button
                    block
                    variant="success"
                    className="reButton"
                    onClick={ask}
                    disabled={true}
                  >
                    {Loaded ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      "Start Recharge"
                    )}
                  </Button>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Banner;
