import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'
import sophie from './img/sophie-5b75317e49.png';

function Cookie() {
    return (
        <div>
              <Header/>
             <Container fluid className="countryBanner">
                 <h2 className="bannerh2">Cookie Statement</h2>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mr-auto ml-auto mt-5 mb-4">
                        <p>We use cookies on this website. A cookie is a small file that, together with pages of this website, is sent to and stored on the hard drive of your computer by your browser. The information stored therein can be sent back to our servers during a subsequent visit.</p>
                        <p>The use of cookies is of great importance for the proper functioning of our website, but cookies that don’t seem to have an immediate effect are also very important. Thanks to the (anonymous) input of visitors, we can improve the use of the website and make it more user-friendly.</p>
                        <h5>Types of cookies that we use</h5>
                        <h6>Functional cookies</h6>
                        <p>These are cookies that we need to make the website function properly or that make it easier for you to use the website. Examples include saving the content of your shopping cart or closing the cookie notification.</p>
                        <h6>Tracking cookies</h6>
                        <p>Our advertisers place “tracking cookies” on your device. They use these cookies to monitor which pages you visit from their network, in order to build a profile of your online surfing behavior. This profile is build up based on comparable information that they receive from your visit to other websites in their network. This profile won’t be linked to your name, address, email address, and other information known to us.</p>
                        <h6>Analytical cookies</h6>
                        <p>These generate an anonymous cookie every time you visit a website. These cookies know whether you’ve visited the website before or not. A cookie is generated during a first visit only. During subsequent visits, this cookie will be used. This cookie is only used for statistical purposes.</p>
                        <h5>Permission</h5>
                        <p>Not all cookies are made to collect your data. Functional cookies, for example, don’t collect personal data. No permission is required to place these cookies. Therefore, they’re immediately loaded when you visit the website.

With analytical cookies it sometimes is and sometimes isn’t necessary to ask permission beforehand to place the cookies. This website, for example, uses Google Analytics. Google always requires asking for approval of the placement of these cookies in its terms and conditions.</p>
<p>To give website visitors more influence, Google has developed a Google Analytics Opt-out Browser Add-on. This Add-on prevents data about your visit from being sent to Google Analytics. The Google Analytics Opt-out Browser Add-on doesn’t prevent information from being sent to other web analytics software.</p>
<h5>Block cookies in its entirety</h5>
<p>You can always turn off cookies yourself or remove them from your device. How you do this depends on the type of internet browser and device. If you choose to remove cookies, the website won’t function properly anymore and you can’t use our services.</p>
<h5>Changes and contact</h5>
<p>From time to time, it may be necessary for us to change this cookie policy. We advise you to visit this page regularly to check if there have been any changes. If you have any questions and/or comments, please contact privacy@cg.nl or:</p>
<ul>
    <li></li>
</ul>
                        
                        </Col>
                        </Row>
                        <Row>
                    <Col className="homeFaq" md={8}>
                    <img
        src={sophie}
        alt=""
      />
                    <div>
                        <h6>Need advice or help?</h6>
                        <p>Please check our <a href="/Faq">Frequently Asked Questions.</a></p>
                    </div>
                    </Col>
                </Row>
                        
                        </Container>
            <Footer/>
        </div>
    )
}

export default Cookie
