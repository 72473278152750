import React from 'react'
import { Container,Col,Row,Card,Accordion,Button } from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'

function Faq() {
    return (
        <div>
            <Header/>
            <Container fluid className="countryBanner">
                 <h2 className="bannerh2">Frequently Asked Questions</h2>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mr-auto ml-auto mt-5 mb-4">
                    <Accordion >
  <Card>
    <Card.Header>
    <h6>What is Ezetopmobile.com?</h6>
    </Card.Header>
      <Card.Body>No matter how many borders you cross, thanks to Recharge’s fast and safe service, you’ll easily stay in touch with one another. Recharge yours or
      </Card.Body>
      <Accordion.Collapse eventKey="0">
      <p>someone else’s call credit or data 24/7 in over 140 countries. Depending on the product you choose, we’ll directly send the credit to your phone or as a recharge code by email. We make sure you’ll always stay connected.</p>
      </Accordion.Collapse>
    <Accordion.Toggle as={Button} variant="link" eventKey="0">
        Read More
      </Accordion.Toggle>  
  </Card>

  <Card>
    <Card.Header>
    <h6>How does the mobile recharge work?</h6>
    </Card.Header>
      <Card.Body>No matter how many borders you cross, thanks to Recharge’s fast and safe service, you’ll easily stay in touch with one another. Recharge yours or
      </Card.Body>
      <Accordion.Collapse eventKey="1">
      <ol>
          <li>Choose the country</li>
          <p>Where is the phone you want to refill?</p>
          <li>Select carrier and product</li>
          <p>Let us know which product you’re going to buy</p>
          <li>Fill in your information</li>
          <p>Fill in your email address and/or the recipient’s phone number</p>
          <li>Pay and receive</li>
          <p>After your payment the order will be sent immediately!</p>
      </ol>
      </Accordion.Collapse>
    <Accordion.Toggle as={Button} variant="link" eventKey="1">
        Read More
      </Accordion.Toggle>  
  </Card>

  <Card>
    <Card.Header>
    <h6>Make safe and secure payments</h6>
    </Card.Header>
      <Card.Body>We make sure the information you give is secured
      </Card.Body>
      <Accordion.Collapse eventKey="2">
          
      <p className="mt-2">Privacy and safety are very important to us at Ezetopmobile.com. That’s why there’s a <a href="k">Privacy Policy</a> and an explanation on <a href="k">how safe Ezetopmobile.com</a> is. You can pay online without worrying!</p>
      </Accordion.Collapse>
    <Accordion.Toggle as={Button} variant="link" eventKey="2">
        Read More
      </Accordion.Toggle>  
  </Card>
  
</Accordion>
                        </Col>
                        </Row>


                
            </Container>
            <Footer/>
        </div>
    )
}

export default Faq
