import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";

function NotFound() {
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <center className=" p-5">
              <h1 className="mb-3 mt-5 errorh">404</h1>
              <h4 className="mb-3">OPPS! PAGE NOT FOUND</h4>
              <Button href="/" className="reButton mt-4 mb-4">
                Return Home
              </Button>
            </center>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default NotFound;
