import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "./img/logo.png";
import footerpayment from "./img/footerp.png";

function Footer() {
  return (
    <Container fluid className="footer">
      <Container>
        <Row>
          <Col xs={12} md={3}>
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Recharge"
              height="64"
            />
          </Col>
          <Col xs={6} md={3}>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/About">About Us</a>
              </li>
              <li>
                <a href="/Safety">Safety</a>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={3}>
            <ul>
              <li>
                <a href="/Faq">Frequently asked questions</a>
              </li>
              <li>
                <a href="/Privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/Conditions">General conditions</a>
              </li>
              <li>
                <a href="/Cookies">Cookie statement</a>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={3}>
            <img
              alt="Payment methods"
              title="Payment methods"
              src={footerpayment}
            ></img>
          </Col>
        </Row>
      </Container>
      <Container fluid className="copyright">
        <p className="text-center m-0">
          © Ezetopmobile {new Date().getFullYear()}. All rights reserved.
        </p>
      </Container>
    </Container>
  );
}

export default Footer;
