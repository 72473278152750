import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
import Footer from "./Footer";
import Header from "./Header";
import { firebase } from "./Setup";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

function SignIn() {
  const [inEmail, setInEmail] = useState("");
  const [InPassword, setInPassword] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signing, setSigning] = useState(false);
  const [Goto, setGoto] = useState(false);

  const displayName = "Awesome User";

  if (Goto === true) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const onLoginPress = (e) => {
    e.preventDefault();
    setSigning(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(inEmail, InPassword)
      .then((user) => {
        setGoto(true);
        swal("Welcome Back!", "You Logged In Succesfully!", "success");
      })
      .catch((error) => {
        swal("ooopps!", error.message, "warning");
        setSigning(false);
      });
  };

  const onRegisterPress = (e) => {
    e.preventDefault();
    setSigning(true);
    if (password !== confirmPassword) {
      swal("Sorry!", "Passwords dont match!", "warning");
      setSigning(false);
      return;
    }

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        const timestamp = firebase.firestore.FieldValue.serverTimestamp();
        const uid = response.user.uid;
        const data = {
          lastEdittedAt: timestamp,
          id: uid,
          email,
          firstName: "",
          lastName: "",
          postalCode: "",
          country: "",
          city: "",
          streetAddress: "",
          phoneNumber: "",
          displayName,
        };

        firebase
          .auth()
          .currentUser.sendEmailVerification()
          .then(function () {
            swal("Verification Email Sent to " + user.email, {
              icon: "success",
            });
          })
          .catch(function (error) {
            swal(error.message);
          });

        const user = firebase.auth().currentUser;

        user
          .updateProfile({
            displayName: "Awesome User",
          })
          .then(function () {
            console.log("success");
          })
          .catch(function (error) {
            console.log(error);
          });
        const usersRef = firebase.firestore().collection("users");
        usersRef
          .doc(uid)
          .set(data)
          .then(() => {
            swal("Welcome!", "You Signed Up Succesfully!", "success");
            setGoto(true);
            setSigning(false);
          })
          .catch((error) => {
            setSigning(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setSigning(false);
        swal(error.message);
      });
  };

  return (
    <div>
      <Header />
      <Container fluid className="countryBanner"></Container>
      <Container>
        <Row>
          <Col md={4} className="mr-auto ml-auto mt-5 mb-5">
            <Card className="p-4">
              <IconContext.Provider value={{ className: "signIcon mb-4" }}>
                <FaUserCircle />
              </IconContext.Provider>
              <Tabs defaultActiveKey="signin">
                <Tab eventKey="signin" title="SIGN IN">
                  <Form className="mt-3">
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setInEmail(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setInPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    <Button
                      variant="success"
                      type="submit"
                      block
                      className="reButton mt-4 mb-3"
                      onClick={onLoginPress}
                    >
                      {signing ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        " SIGN IN"
                      )}
                    </Button>
                  </Form>
                </Tab>
                <Tab eventKey="signup" title="REGISTER">
                  <Form className="mt-3">
                    <Form.Group>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Confirm password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      variant="success"
                      type="submit"
                      block
                      className="reButton mt-4 mb-4"
                      onClick={onRegisterPress}
                    >
                      {signing ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "CREATE"
                      )}
                    </Button>
                  </Form>
                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default SignIn;
