import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'
import sophie from './img/sophie-5b75317e49.png';

function Privacy() {
    return (
        <div>
              <Header/>
             <Container fluid className="countryBanner">
                 <h2 className="bannerh2">Privacy Policy</h2>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mr-auto ml-auto mt-5 mb-4">
                        <h3>Privacy Statement</h3>
                        <p>We at Ezetopmobile.com value your privacy. When you use our services to buy top-ups for your phone credit, entertainment cards or prepaid credit cards, we want you to be certain of a fast, safe and simple transaction, with your privacy guaranteed.</p>
                        <h3>Why your data is collected</h3>
                        <p>“Customer First” is one of our four core values. To follow through on this promise we need to know more about you – while respecting your privacy.</p>
                        <p>To carry out your purchase agreement, we use data provided by you when you use our services or contact us. This data could be your name, email address, address, phone number. Our customer care services additionally use your order ID when you have an inquiry about your order.

We have an automated system that may use your personal data to prevent and fight fraud.</p>
<p>We may use your personal data to improve our services and increase the quality of our webshops, as well as for direct marketing purposes which may include analysing, segmenting into profiles and building online audiences. 

By having a better understanding of you and your preferences, we are able to offer a personal and authentic experience. This includes marketing and transactional communications you receive from us, as well as other notifications and advertisements you see in our webshops.</p>
<h3>Duration of the data storage</h3>
<p>We only keep your data for as long as necessary to deliver our services, unless we’re legally obliged to keep the data for a longer period.</p>
<p>In order to process your purchase, we may need to share your personal data with third parties like payment agencies and other companies for the completion of the relevant services. Rest assured we will never sell your data to anyone.

In a few cases, we might have to pass on personal data to companies situated outside the European Economic Area. Naturally, the security of your data remains a priority. We’ll always require an adequacy decision from the European Commission.</p>
<h3>Cookies</h3>
<p>Our websites use functional, analytical, and tracking cookies. We need functional cookies in order for the website to function properly and to complete your purchases. We place the other cookies to offer you tailored web pages. Some of these cookies are placed by external parties. In our Cookie Statement, you will find more information on this topic.</p>
<h3>Your rights</h3>
<p>If you do not want to receive any emails or other communications from us, you may always unsubscribe. If you choose to unsubscribe, you can do so via the unsubscribe link in emails received from us. 

You have the legal right to ask us to inspect, rectify, or erase all personal data that we’ve stored about you. You also have the right to object to or limit the processing of your data, and transfer it in a machine-readable format. Official requests can be sent to datarequest@cg.nl. In these cases, we may ask you for a copy of your identity document or passport.  If you send us a copy of your identity document or passport, please cover your photo, MRZ (machine readable zone, the strip with numbers at the bottom of your passport), passport number, and your citizen service number.</p>
<h3>Questions?</h3>
<p>If you believe that we’re using your personal data incorrectly or that we don’t deal with your questions correctly, please contact our customer care department. If we are not able to help you, you can file a complaint to the Dutch Data Protection Authority.</p>
<h3>Changes and contact details</h3>
<p>We may revise or amend this statement from time to time to reflect changes to our business or changes in the law. We advise you to visit this page regularly to check if there have been any changes. If you have any questions about our privacy statement, please send an email to privacy@cg.nl or write to Ezetopmobile.com, a trade name of Top Up B.V. and Recharge B.V., Kerkenbos 13-01, 6546 BG, Nijmegen, The Netherlands.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="homeFaq" md={8}>
                    <img
        src={sophie}
        alt=""
      />
                    <div>
                        <h6>Need advice or help?</h6>
                        <p>Please check our <a href="/Faq">Frequently Asked Questions.</a></p>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default Privacy
