import firebase from "firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyC6a3lmVKJOrpYnNnf_I6uGjjwEhxE1oZI",
  authDomain: "ezetop-5fa4f.firebaseapp.com",
  projectId: "ezetop-5fa4f",
  storageBucket: "ezetop-5fa4f.appspot.com",
  messagingSenderId: "90249087888",
  appId: "1:90249087888:web:6ca38eb09efed48654c115"
};

//if (firebase.apps.length) {
firebase.initializeApp(firebaseConfig);

//}

export { firebase };
