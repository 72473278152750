import React from "react";
import { Container, Col, Card, Row } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import ReactCountryFlag from "react-country-flag";
const countryIso = require("iso-3166-country-list");

function Country({ Countries }) {
  return (
    <div>
      <Header />
      <Container fluid className="countryBanner">
        <h2 className="bannerh2">Countries</h2>
      </Container>
      <Container className="mt-5">
        <h3 className="text-center">Recharge in over 140 countries</h3>
        <p className="text-center w-75 ml-auto mr-auto mt-3 mb-5">
          Below you can find the countries that Ezetopmobile.com operates in, so
          look for your country! You can do this by going through the list below
          or filling in the country of your choice in the search bar.
        </p>
        <Row>
          {Countries &&
            Countries.map((item, index) => {
              const code = countryIso.code(item);
              return (
                code && (
                  <Col md={2} xs={6} key={index}>
                    <Card className="p-3 pCard">
                      <ReactCountryFlag
                        countryCode={code}
                        svg
                        style={{
                          width: "100%",
                          height: "auto",
                        }}
                        title={item}
                      />
                    </Card>
                    <p className="text-center">{item}</p>
                  </Col>
                )
              );
            })}
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Country;
