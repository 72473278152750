import React from 'react'
import { Container, Row,Col } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { FaLock, FaTicketAlt ,FaTv} from 'react-icons/fa'
import Footer from './Footer'
import Header from './Header'
import sophie from './img/sophie-5b75317e49.png';

function About() {

    return (
        <div>
            <Header/>
            <Container fluid className="aboutBanner">
                <h2 className="bannerh2">About us</h2>
            </Container>
            <Container>
                <Row>
                   
                </Row>

                <Row>
                    <Col md={8} className="aboutChoose  ml-auto mr-auto mt-5 mb-5">
                    <h4>Ezetopmobile.com</h4>
                    <p>
                    You know Ezetopmobile.com as the go-to place for international mobile top-up. Or Rapido.com as the comprehensive store for prepaid digital codes. Now, all your favourite digital prepaid products are in one place!
                    </p>
                    <p>Recharge a phone anywhere in the world. Or get a digital prepaid code for your favourite entertainment service, game or online shop. It’s as fast, safe and simple as always. Only, better.</p>
                    <p>Welcome to the new Ezetopmobile.com!</p>
                    <hr/>
                    <h5>Recharge credit worldwide in seconds</h5>
                    <p>Here at Ezetopmobile.com, we believe in living on your own terms. Whether you want to buy call credit, enjoy your favourite tv shows and music, or play games, you should do it your way. That means from anywhere, at any time, using whatever payment method you prefer. You’re in charge.</p>
                    <p>Digital prepaid credit lets you enjoy the things you like on your terms. And we’ve made it our mission to ensure that buying digital credit is fast, safe and simple.</p>
                    <h5>The people behind Ezetopmobile.com</h5>
                    <p>Since it all started in 2002, we’ve grown a lot. From a small group of enthusiasts that could fit in a single room, we’ve become a global team of 100+ people. You’ll find us in our offices in Amsterdam.</p>
                    <p>We’re bound together by our passion to make people’s lives easier. That’s the one thing that’s never going to change, however fast we grow.</p>
                    <h5>Who we are: our contact details</h5>
                    <p>The Ezetopmobile.com platform for international mobile top-up is a service of Recharge B.V., which forms part of Creative Group.</p>
                    <h6>Our (contact) details:</h6>
                     <h4 className="mt-3">Why choose Ezetopmobile.com</h4>
                     <IconContext.Provider value={{ className: "aboutIcons" }}>
                        <ul>
                            <li>
                             <FaTv/>
                              Instant digital delivery: <span>You’ll be able to use your digital credit within seconds.</span>
                              </li>
                            <li>
                              <FaLock/>
                              Trusted by millions: <span>We’re proud to have over 5 million customers worldwide.</span>
                              </li>
                            <li>
                              <FaTicketAlt/>
                              Pay safely & securely: <span>Order in seconds using your favourite payment method.</span>
                              </li>
                         </ul>
                         </IconContext.Provider>
                    </Col>
                </Row>
                <Row>
                    <Col className="homeFaq" md={8}>
                    <img
        src={sophie}
        alt=""
      />
                    <div>
                        <h6>Need advice or help?</h6>
                        <p>Please check our <a href="/Faq">Frequently Asked Questions.</a></p>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default About
