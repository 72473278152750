import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Table, Button, Spinner } from "react-bootstrap";
import Footer from "./Footer";
import Header from "./Header";
import ReactCountryFlag from "react-country-flag";

import Axios from "axios";
import { Redirect } from "react-router-dom";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { AuthContext } from "./context";
import { firebase } from "./Setup";
import swal from "sweetalert";
import logo from "./img/logo.png";
import countryIso from "iso-3166-country-list";

function ProdCart(props) {
  const user = useContext(AuthContext);
  const uniqueUser = user && user.uid;
  const [Loaded, setLoaded] = useState(false);
  const [Reply, setReply] = useState(false);
  const carrier = props.location.state;
  const provider = carrier.provider;

  const [profile, setProfile] = useState({});
  const code = provider && countryIso.code(provider.product_Country);
  const profileRef =
    user && firebase.firestore().collection("users").doc(uniqueUser);
  const ordersRef = firebase.firestore().collection("orders");
  const [goto, setGoto] = useState(false);

  const topUp = carrier.denomination * provider.prodSellPrice;
  const topPay = carrier.denomination * provider.prodSellPrice + 0.05;
  const amount = topUp.toFixed(2);
  const amountPay = topPay.toFixed(2);

  //Get Profile Details
  useEffect(() => {
    profileRef.onSnapshot(function (doc) {
      if (doc.exists) {
        setProfile(doc.data());
        setLoaded(true);
      } else {
        // doc.data() will be undefined in this case
        swal("No such document in Database!");
      }
    });
  }, []);

  const config = {
    public_key: "FLWPUBK-b76a150883d56065f4169b263ba7ef43-X",
    tx_ref: Date.now(),
    amount: amountPay,
    currency: "USD",
    payment_options: "mobilemoney",
    customer: {
      email: user.email,
      phonenumber: carrier.phone,
      name: profile.firstName + " " + profile.lastName,
    },
    customizations: {
      title: "Ezetopmobile Checkout",
      description: "Payment for recharge",
      logo: "https://firebasestorage.googleapis.com/v0/b/ezetop-5fa4f.appspot.com/o/logo.png?alt=media&token=a5da195c-270b-423d-9a5a-baf4e67fb132",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  function handlePay(payResponse) {
    setReply(true);
    setLoaded(false);
    Axios.post(
      "https://ezetopmobile.com/opp/reload",
      {
        code: provider.operatorCode,
        phone: carrier.phone,
        denom: parseInt(carrier.denomination),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        const order = response.data;
        const param = {
          order,
          payResponse,
        };
        setLoaded(true);
        setReply(false);
        addOrder(param);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //Handle Add Reload Order
  const addOrder = (param) => {
    const order = param.order;
    const payResponse = param.payResponse;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const end = () => {};

    ordersRef
      .add({
        amountPaid:
          payResponse && payResponse.amount + " " + payResponse.currency,
        payStatus: payResponse && payResponse.status,
        tx_ref: payResponse && payResponse.tx_ref,
        flw_ref: payResponse && payResponse.flw_ref,
        time: timestamp,
        orderID: payResponse && payResponse.transaction_id,
        receiver: carrier.phone,
        user_email: profile && profile.email,
        userID: uniqueUser,
        price: order && order.productPrice + " " + order.walletCurrency,
        receiverGets: order && order.denomination + " " + order.amountCurrency,
        trangloTransactionId: order && order.trangloTransactionId,
        dealerTransactionId: order && order.DealerTransactionId,
        status: order && order.status,
        operatorTransactionId: order.operatorTransactionId,
        country: provider.product_Country,
        lastUpdate: timestamp,
      })
      .then(() => {
        const message =
          "Recharge of " +
          order.denomination +
          " " +
          order.amountCurrency +
          " Sent to " +
          carrier.phone +
          " Succesfully!";
        switch (order.status) {
          case "000":
            setGoto(true);
            swal("Good job!", message, "success");
            end();
            break;
          case "968":
            setGoto(true);
            swal(
              "Recharge Pending!",
              "When recharge is completed an email would be sent to you",
              "success"
            );
            end();
            break;
          default:
            setGoto(true);
            swal("Sorry!", "Something went wrong Try Again", "danger");
            end();
        }
      })
      .catch(function (error) {
        swal("ooopps!", error.message, "warning");
      });
    end();
  };

  if (goto) {
    return (
      <Redirect
        to={{
          pathname: "/Account",
        }}
      />
    );
  }

  if (!carrier) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }
  if (Loaded) {
    return (
      <div>
        <Header />
        <Container>
          <Row>
            <Col md={8} className="ml-auto mr-auto mt-5">
              <h1>Summary</h1>
              <Table size="sm">
                <thead>
                  <tr>
                    <th>Country:</th>
                    <th>
                      {" "}
                      <ReactCountryFlag
                        countryCode={code}
                        svg
                        className="mr-2 rounded-circle"
                        style={{
                          width: "2em",
                          height: "2em",
                        }}
                        title={provider.product_Country}
                      />
                      {""}
                      {provider.product_Country}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number:</td>
                    <td>{carrier.phone}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Operator:</td>
                    <td>({provider.productCode})</td>
                    <td></td>
                  </tr>
                  <tr className="receive">
                    <td>Receiver Gets:</td>
                    <td>
                      {carrier.denomination +
                        " " +
                        provider.product_Country +
                        " Currency"}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Top-up amount:</td>
                    <td></td>
                    <td>{amount + " USD"}</td>
                  </tr>
                  <tr className="total">
                    <td>Total:</td>
                    <td></td>
                    <td>{amount + " USD"}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col md={8} className="ml-auto mr-auto mt-4">
              <Button
                className="reButton mt-1 mb-5"
                block
                onClick={() => {
                  handleFlutterPayment({
                    callback: (response) => {
                      if (response.status === "successful") {
                        handlePay(response);
                        closePaymentModal(); // this will close the modal programmatically
                      } else {
                        swal("Transaction" + response.status);
                        closePaymentModal(); // this will close the modal programmatically
                      }
                    },
                    onClose: () => {},
                  });
                }}
              >
                Pay {amount + " USD"} NoW
              </Button>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    );
  } else {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <center>
              <img
                src={logo}
                className="d-inline-block align-top mt-5"
                alt="Recharge"
                height="64"
              />
              <br />
              <Spinner animation="border" className="mt-3 spiColor mb-4" />
              {Reply && (
                <h4>
                  Sending Airtime Transfer to +{carrier.phone} in{" "}
                  {provider.product_Country}
                </h4>
              )}
            </center>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProdCart;
