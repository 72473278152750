import React, { useContext, useState } from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "./img/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "./context";
import { firebase } from "./Setup";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";

function Header() {
  const user = useContext(AuthContext);
  const [Goto, setGoto] = useState(false);

  const location = useLocation().pathname;

  if (Goto) {
    return (
      <Redirect
        exact
        to={{
          pathname: "/Signin",
        }}
      />
    );
  }

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          // if (
          //   location === "/Account" ||
          //   "/Cart" ||
          //   "/Cart_flex" ||
          //   "/Cart_prod" ||
          //   "/Products"
          // )
          setGoto(true);
        },
        swal("Signed Out Succesfully!", {
          icon: "success",
        }).then()
      )
      .catch((error) => {
        swal("ooopps!", error, "warning");
      });
  };
  return (
    <div className="header">
      <Container>
        <Navbar variant="light" expand="lg" bg="white" className="p-1">
          <Navbar.Brand href="/" className="m-1 p-0">
            <img
              src={logo}
              className="d-inline-block align-top"
              alt="Ezetopmobile"
              height="48"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Nav>
              <NavLink className="nav-link" to="/" activeClassName="activeLink">
                Home
              </NavLink>
              <NavDropdown title="About Us" id="basic-nav-dropdown">
                <NavLink className="dropdown-item" to="/About">
                  About Us
                </NavLink>
                <NavLink className="dropdown-item" to="/Countries">
                  Countries
                </NavLink>
                <NavLink className="dropdown-item" to="/Carriers">
                  Carriers
                </NavLink>
                <NavLink className="dropdown-item" to="/Safety">
                  Safety
                </NavLink>
              </NavDropdown>
              <NavLink className="nav-link" to="/Faq">
                Customer Care
              </NavLink>
              <NavLink className="nav-link" to={user ? "/Account" : "/SignIn"}>
                Account
              </NavLink>
            </Nav>
            {user && (
              <Button className="reButton ml-2" size="sm" onClick={signOut}>
                Sign Out
              </Button>
            )}
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
}

export default Header;
