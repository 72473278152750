import React from 'react'
import {Container, Row,Col} from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'
import safep from './img/betaalmethodes.jpg'
import sophie from './img/sophie-5b75317e49.png';

function Safety() {
    return (  
        <div>
            <Header/>
             <Container fluid className="carrierBanner">
                 <h2 className="bannerh2">Placing an order at Ezetopmobile.com is nothing to worry about</h2>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mr-auto ml-auto mt-5 mb-5">
                        <p>Let’s start at the beginning. We make it possible for you to refill phone credit all over the whole world in only 3 steps. This can be your own credit, or that of friends and family. Of course we could explain how Ezetopmobile.com works, but we don’t think you were looking for that. We googled ourselves (yes, we really did) and found some questions from customers:</p>
                        <ul>
                            <li>“How safe is Ezetopmobile.com?”</li>
                            <li>“How do I refund my order of Ezetopmobile.com?”</li>
                            <li>“Does Ezetopmobile.com have a customer service?”</li>
                        </ul>
                        <p>That’s why we want to discuss the most frequently questions on fluctuating prices, failed payments, refunds and data credit. Your insecurities will vanish into thin air.</p>
                   <h6>How safe is Ezetopmobile.com?</h6>
                   <p>We can’t wait to tell you! Ezetopmobile.com was set up in 2013 and is a part of Creative Group. From our HQ in the Netherlands, we’re here for you 24/7.</p>
                   <ul>
                       <li>Over 1 million people use Ezetopmobile.com</li>
                       <li>Every 30 seconds a refill takes place</li>
                       <li>Customers give Ezetopmobile.com an average of 8 on Trustpilot</li>
                   </ul>
                   <p>Apart from that, we work with reliable companies like Visa, Mastercard, American Express and PayPal to make sure you can pay safely. Accordingly, we handle your personal details with the maximum care, as you can read in our privacy policy. So, no reason to worry!</p>
                   <img
                   src={safep}
                   alt=""
                   className="mb-2"
                   width="80%"
                   />
                   <h6>My order has failed, but the money was deducted from my account. How can I get my money back?</h6>
                   
                   <p>It can happen that there’s a disturbance at the provider, or that for a provider only a certain amount of international refills are allowed. As you can see, there are multiple reasons why your refill can fail. If your direct refill fails, for whatever reason, you can request a refund of the entire amount (including the service fee).

When we do this, it’s possible that the amount remains reserved on your creditcard. But don’t worry, this will be added to your balance automatically. When you pay with creditcard it can take 3-4 working days until you receive the amount back in your account.</p>
                   <h6>Can I refund my order myself?</h6>
                   <p>Of course you can! Sometimes it takes a little while before the refill is completed, this could have multiple reasons. Like a disturbance at the provider or when there are too many refills at the same time, we will let you know by email if this is the case. If you think it’s taking too long you can decide if you want to refund your order. You can do this through the link that’s in the email regarding the delay.

Also, if you recharge with a recharge code, this should always work perfectly. Is your code not working? Then contact our Customer Care Team. They can help you with your code. If you received the wrong code, they will take the code back. In this case, one of our Customer Care colleagues will tell you how to return your code, since this can differ from case to case.</p>
                   <h6>How can the amount I pay differ from the amount of credit that I receive?</h6>
                   <p>This has to do with the exchange rate. Think of:</p>
                   <ul>
                       <li>Slightly unfavorable exchange rates</li>
                       <li>A refill for a higher amount in comparison to a lower amount could be cheaper</li>
                   </ul>
                   <p>Do we guarantee you get the best price? We sure do! But we’re always dependent on our supplier. You always receive the amount of credit indicated on our website, that’s a fact.</p>
                   <h6>I refilled my credit, why can’t I use the internet?
</h6>
                   <p>We understand the confusion. Time to clear things up. When you place an order with us, you order phone credit. It’s all up to the provider if you can use the internet or not with this credit. Unfortunately, we can’t change that. Contact your provider if you want to know more.

Are things still unclear to you after reading this? Please don’t hesitate to contact our customer care. There’s always a colleague ready to answer all your questions in English, or even in German, Dutch, French and Spanish as well.</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="homeFaq" md={8}>
                    <img
        src={sophie}
        alt=""
      />
                    <div>
                        <h6>Need advice or help?</h6>
                        <p>Please check our <a href="/Faq">Frequently Asked Questions.</a></p>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default Safety
