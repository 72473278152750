import React, { useState, useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  Card,
} from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaRegUserCircle } from "react-icons/fa";
import { AuthContext } from "./context";
import { firebase } from "./Setup";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import Header from "./Header";
import Footer from "./Footer";
import logo from "./img/logo.png";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

function Account({ Providers, Products }) {
  const user = useContext(AuthContext);
  const verified = user.emailVerified;
  const unique = user && user.uid;
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [Loaded, setLoaded] = useState(false);
  const [showRecharge, setShowRecharge] = useState(false);
  const [denomSelect, setDenomSelect] = useState("");
  const handleReClose = () => setShowRecharge(false);
  const handleReShow = () => setShowRecharge(true);
  const handleAddClose = () => setShowAdd(false);
  const handleAddShow = () => setShowAdd(true);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
  const [Spin, setSpin] = useState(false);
  const [goto, setGoto] = useState(false);
  const [phone, setPhone] = useState("");
  const [checking, setChecking] = useState(false);
  const [Contacts, setContacts] = useState([]);
  const [orders, setOrders] = useState([]);
  const [refreshed, setRefreshed] = useState(false);
  //Pfrofile Ref
  const [profile, setProfile] = useState({});
  const [provider, setProvider] = useState([]);

  const profileRef =
    user && firebase.firestore().collection("users").doc(unique);

  //
  const contactsRef = firebase.firestore().collection("contacts");
  const ordersRef = firebase.firestore().collection("orders");

  //Edit Modal
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [PostalCode, setPostalCode] = useState("");
  const [Country, setCountry] = useState("");
  const [StreetAddress, setStreetAddress] = useState("");
  const [City, setCity] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  //
  //Add Phone Modal
  const [ContactName, setContactName] = useState("");
  const [ContactCountry, setContactCountry] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  //

  //Get Profile Details
  useEffect(() => {
    setRefreshed(true);
    profileRef.onSnapshot(function (doc) {
      if (doc.exists) {
        setProfile(doc.data());
        setLoaded(true);
      } else {
        // doc.data() will be undefined in this case
        swal("No such document in Database!");
      }
    });
  }, []);

  //Get Contacts
  useEffect(() => {
    contactsRef.onSnapshot(function (querySnapshot) {
      const posts = [];
      querySnapshot.forEach(function (doc) {
        const post = doc.data();
        post.id = doc.id;
        posts.push(post);
      });
      setContacts(posts);
    });
  }, []);

  //Get Orders
  useEffect(() => {
    ordersRef.orderBy("time", "desc").onSnapshot(function (querySnapshot) {
      const posts = [];
      querySnapshot.forEach(function (doc) {
        const post = doc.data();
        post.id = doc.id;
        posts.push(post);
      });
      setOrders(posts);
    });
  }, []);

  //Handle Profile edit
  const onEditPress = (e) => {
    e.preventDefault();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    profileRef
      .update({
        lastEdittedAt: timestamp,
        firstName: FirstName ? FirstName : profile.firstName,
        lastName: LastName ? LastName : profile.lastName,
        city: City ? City : profile.city,
        country: Country ? Country : profile.country,
        postalCode: PostalCode ? PostalCode : profile.postalCode,
        streetAddress: StreetAddress ? StreetAddress : profile.streetAddress,
        phoneNumber: PhoneNumber ? PhoneNumber : profile.phoneNumber,
      })
      .then(
        swal("Good job!", "Profile Editted Succesfully!", "success"),
        handleEditClose()
      )
      .catch((error) => {
        swal("ooopps!", error, "warning");
      });
  };

  //Handle Add Phone Number
  const onAddPress = (e) => {
    e.preventDefault();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    const uid = uuidv4();
    const res = () =>
      contactsRef.add({
        time: timestamp,
        id: uid,
        name: ContactName,
        country: ContactCountry,
        phoneNumber: ContactNumber,
        unique: unique,
      });
    res()
      .then(
        swal("Good job!", "Contact Added Succesfully!", "success"),
        handleAddClose()
      )
      .catch(function (error) {
        swal("ooopps!", error, "warning");
      });
  };

  function deleteContact(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Contact's details!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        contactsRef
          .doc(id)
          .delete()
          .then(function () {})
          .catch(function (error) {
            swal("ooopps!", error, "warning");
          });
        swal("Contact has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Contact Details safe!");
      }
    });
  }

  function deleteOrder(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Order's details!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ordersRef
          .doc(id)
          .delete()
          .then(function () {})
          .catch(function (error) {
            swal("ooopps!", error, "warning");
          });
        swal("Recharge Order has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Recharge Order Details safe!");
      }
    });
  }

  function checkStatus(data) {
    setChecking(true);
    Axios.post(
      "https://ezetopmobile.com/opp/status",
      { tranID: data.trans, trangloID: data.tranglo, id: data.id },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        const status = response.data.toString();
        setChecking(false);
        switch (status) {
          case "968":
            return swal(
              "Reload Pending",
              "Airtime Reload is still Pending",
              "warning"
            );

          case "000":
            return swal(
              "Reload Succesful",
              "Airtime Reload was Succesful",
              "success"
            );

          default:
            swal("!ooops", "Sorry Airtime Reload Failed", "warning");
            break;
        }
      })
      .catch((err) => {
        setChecking(false);
        swal(err.message);
      });
  }

  const handleVerify = () => {
    firebase
      .auth()
      .currentUser.sendEmailVerification()
      .then(function () {
        swal("Verification Email Sent to " + user.email, {
          icon: "success",
        });
      })
      .catch(function (error) {
        swal(error);
      });
  };

  function handleRecharge(number) {
    setSpin(true);
    setPhone(number);
    const userPosts = Axios.post(
      "https://ezetopmobile.com/opp/product",
      { number },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    userPosts
      .then((response) => {
        setProvider(response.data);
        if (provider) {
          handleReShow();
          setSpin(false);
        }
      })
      .catch((error) => {
        swal("Please Try Again");
        setSpin(false);
      });
  }

  const accountContacts =
    Contacts &&
    Contacts.filter(function (Contacts) {
      return Contacts.unique === unique;
    });
  const accountOrders =
    orders &&
    orders.filter(function (order) {
      return order.userID === unique;
    });
  const amtStp = provider.amountStep && provider.amountStep;
  const amountStep = parseInt(amtStp);
  const denomType = provider.denomType;

  if (goto) {
    return (
      <Redirect
        to={{
          pathname: verified ? "/Cart_prod" : !user ? "/SignIn" : "/Account",
          state: {
            provider,
            phone,
            denomination: denomSelect,
          },
        }}
      />
    );
  }

  //Main Return Function
  if (Loaded) {
    return (
      <div>
        <Header />
        <Container fluid className="carrierBanner"></Container>
        <Container className="accountDetails mt-5 pt-2 mb-4">
          <Row className="ml-auto mr-auto">
            <Col>
              {" "}
              <h1>MY ACCOUNT</h1>
            </Col>

            <Col>
              <div className="border-right pr-3 mr-2">
                <IconContext.Provider value={{ className: "userIcon" }}>
                  <FaRegUserCircle />
                </IconContext.Provider>
                <h4 className="text-center">My Profile</h4>
              </div>
            </Col>

            <Col>
              <div>
                <p>
                  Your Email: <span>{profile.email}</span>
                </p>
                <Button
                  onClick={handleEditShow}
                  className="reButton mt-1"
                  size="sm"
                >
                  Edit
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        {!verified && (
          <Container>
            <Row>
              <Col className="orders mb-5">
                <div>
                  <p>
                    Plase Click this{" "}
                    <Button variant="success mr-2 ml-2" onClick={handleVerify}>
                      Verify Email
                    </Button>
                    to verify your account
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container>
          <h5>My Orders</h5>

          <Row>
            <Col className="orders mb-5">
              {accountOrders.length >= 1 ? (
                <Table
                  striped
                  bordered
                  hover
                  variant="primary"
                  className="mt-3"
                >
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Recipient No.</th>
                      <th>Country</th>
                      <th>Airtime</th>
                      <th>Amount</th>
                      <th>Payment Status</th>
                      <th>Recharge Status</th>
                      <th>Time</th>
                      <th>
                        {checking && (
                          <Spinner
                            animation="border"
                            className="m-0"
                            size="sm"
                          />
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountOrders &&
                      accountOrders.map((item, index) => {
                        const id = item.id;
                        const success = item.status === "000" && item.status;
                        const pending = item.status === "968" && item.status;
                        const data = {
                          trans: item.dealerTransactionId,
                          tranglo: item.trangloTransactionId,
                          id: item.id,
                        };
                        const status =
                          success || pending
                            ? success
                              ? "Succesful"
                              : "Pending"
                            : "Failed";
                        return (
                          <tr key={index}>
                            <td>{item.orderID}</td>
                            <td>+{item.receiver}</td>
                            <td className="text-uppercase">{item.country}</td>
                            <td>{item.receiverGets}</td>
                            <td>{item.amountPaid}</td>
                            <td>{item.payStatus}</td>
                            <td className="text-danger">
                              {success ? (
                                <p className="text-success p-0 border-0">
                                  {status}
                                </p>
                              ) : (
                                status
                              )}
                              {pending && (
                                <Button
                                  size="sm"
                                  className="btn-warning ml-2"
                                  onClick={() => {
                                    checkStatus(data);
                                  }}
                                >
                                  Check Status
                                </Button>
                              )}
                            </td>
                            <td>
                              {item.time && (
                                <ReactTimeAgo
                                  date={item.time.toDate()}
                                  locale="en-US"
                                />
                              )}
                            </td>
                            <td>
                              <center>
                                <Button
                                  className="deleteButton rounded-circle"
                                  size="sm"
                                  onClick={() => deleteOrder(id)}
                                >
                                  X
                                </Button>
                              </center>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              ) : (
                <div>
                  <p>You don't have any orders yet</p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <Container>
          <h5>My Phone Book</h5>
          <Row>
            <Col>
              <Table striped bordered hover variant="dark" className="mt-3">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Country</th>
                    <th>
                      {Spin && (
                        <center>
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        </center>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accountContacts &&
                    accountContacts.map((item) => {
                      const id = item.id;
                      const number = item.phoneNumber;
                      return (
                        <tr key={item.id}>
                          <td>{item.name}</td>
                          <td>
                            <Button
                              className="bPhone"
                              onClick={() => handleRecharge(number)}
                            >
                              {item.phoneNumber}
                            </Button>
                          </td>
                          <td>{item.country}</td>
                          <td>
                            <center>
                              <Button
                                className="deleteButton rounded-circle"
                                size="sm"
                                onClick={() => deleteContact(id)}
                              >
                                X
                              </Button>
                            </center>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <Button className="mb-5 reButton" onClick={handleAddShow}>
                +Add number
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal show={showEdit} onHide={handleEditShow}>
          <Modal.Header>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="p-3">
              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  placeholder={
                    profile.firstName
                      ? profile.firstName
                      : "Enter Your First Name"
                  }
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  placeholder={
                    profile.lastName ? profile.lastName : "Enter Your Last Name"
                  }
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  placeholder={
                    profile.streetAddress
                      ? profile.streetAddress
                      : "Enter Your Street Address"
                  }
                  onChange={(e) => setStreetAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  placeholder={
                    profile.postalCode
                      ? profile.postalCode
                      : "Enter Your Postal Code"
                  }
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  placeholder={
                    profile.city ? profile.city : "Enter Your City Name"
                  }
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    placeholder={
                      profile.country
                        ? profile.country
                        : "Enter Your Country Name"
                    }
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder={
                      profile.phoneNumber
                        ? profile.phoneNumber
                        : "Enter Your Phone Number"
                    }
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <Form.Text className="text-center text-danger">
                    "Number Must Be In International Format Only"
                  </Form.Text>
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={onEditPress}
              className="reButton"
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showAdd} onHide={handleAddShow}>
          <Modal.Header>
            <Modal.Title>Add Phone Number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Enter Name"
                  onChange={(e) => setContactName(e.target.value)}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    placeholder="Enter Country"
                    onChange={(e) => setContactCountry(e.target.value)}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Phone Number"
                    onChange={(e) => setContactNumber(e.target.value)}
                  />
                  <Form.Text className="text-center text-danger">
                    "Number Must Be In International Format Only"
                  </Form.Text>
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddClose}>
              Close
            </Button>
            <Button variant="primary" onClick={onAddPress} className="reButton">
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* //Recharge Modal */}
        <Modal show={showRecharge} onHide={handleReClose}>
          <Modal.Header className="p-1 justify-content-end pr-2">
            <Button
              variant="danger"
              onClick={handleReClose}
              className="roundBtn"
            >
              X
            </Button>
          </Modal.Header>
          <Card className="p-2 MainCard">
            <Row className="mb-3">
              <Col md={6} xs={6} className="pl-4 pr-1">
                <Card className="p-2 spiColor text-center">
                  Minimum Denomination Value : {provider.minValue}
                </Card>
              </Col>
              <Col md={6} xs={6} className="pr-4 pl-1">
                <Card className="p-2 spiColor text-center">
                  Maximum Denomination Value : {provider.maxValue}
                </Card>
              </Col>
            </Row>
            <Form className="m-3">
              <Form.Group controlId="formBasicEmail">
                <Form.Label>
                  Recharge Amount in the Currency of {provider.product_Country}
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  max={provider.maxValue}
                  min={provider.minValue}
                  onChange={(e) => setDenomSelect(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">
                  {denomType === "2"
                    ? "Please increase amount by " +
                      amountStep +
                      " no decimals eg" +
                      " " +
                      provider.minValue.replace(".00", "") +
                      "+" +
                      amountStep +
                      " = " +
                      (parseInt(provider.minValue) + amountStep)
                    : "Please Note that the only Available denomination is" +
                      " " +
                      provider.maxValue}
                </Form.Text>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                className="reButton"
                block
                onClick={() => denomSelect && setGoto(true)}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Modal>
        <Footer />
      </div>
    );
  } else {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <center>
              <img
                src={logo}
                className="d-inline-block align-top mt-5"
                alt="Recharge"
                height="64"
              />
              <br />
              <Spinner animation="border" className="mt-3 spiColor" />
            </center>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Account;
