import React from 'react';
import { Container,Row,Col } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';
import sophie from './img/sophie-5b75317e49.png';

function Conditions() {
    return (
        <div>
              <Header/>
             <Container fluid className="countryBanner">
                 <h2 className="bannerh2">General Conditions</h2>
            </Container>
            <Container>
                <Row>
                    <Col md={8} className="mr-auto ml-auto mt-5 mb-4">
                        <h3>Our services</h3>
                        <p>With our services you can top up phone credit and buy game cards, entertainment cards and prepaid credit cards from different providers online. It’s fast, safe and simple.

Do you want to know the balance of your phone credit? Or are you looking for the top-up instructions from your provider? On our website you will find this information about various providers. If you have other questions, please check our FAQ page.</p>
<h3>Who are we: our contact details</h3>
<p>

</p>
<p></p>
<h3>Providers</h3>
<p>Via our website, you can buy top-up credit from various providers. The conditions set by each provider apply to the use of the credit.</p>
<h3>Buy credit</h3>
<p>If you buy credit, you will receive a digital code for that credit from us. The provider is the actual supplier of the credit or service and an agreement is created between you and the provider when you activate the credit. Recharge.com is the intermediary. The provider is responsible for the possibility to actually make use of the credit. 

The description of the goods from the various providers on our website is as accurate as possible. Any mistakes or errors in the description of the offer and / or in the images shown are not binding and do not lead to any liability for us and / or the provider.

The offer on our website contains all information and costs associated with buying the credit by making use of our services. This includes additional costs, such as service costs for the services that we provide to you.</p>
<h3>We do not allow abuse or fraud</h3>
<p>We actively track abuse or fraud (including unlawful use of a payment method, theft, fraud, embezzlement or otherwise). All purchases of credit via our website may be checked for abuse or fraud. For this purpose we work with third parties. As soon as we suspect or discover abuse or fraud, we will refuse an order of credit and we will not issue a digital code.

In case of suspected unlawful use, we can ask for a copy of a valid ID, a copy of a bank statement and/or other documents. After this extra check we can decide whether to provide a digital code, but we are not obliged to do so. We take these measures in order to keep the process of buying credit through our website safe for you and everyone else.</p>
<h3>Safe payment</h3>
<p>Digital credit can be purchased safely and securely via our website. If you have purchased credit, you will receive the digital code by email so that you can immediately start using the credit. In some cases, the digital code will also be shown directly on your screen after purchase. 

To ensure you can receive your digital code straight away, please make sure that the email address and mobile number you provide during your purchase process are correct.</p>
<h3>Instant digital delivery</h3>
<p>Once you have received your digital code, you can immediately start using the credit.That’s why during the ordering process we may ask you to agree to an immediate start of the purchase contract and thereby waive your Right of Withdrawal.

Products for which the 14 day Right of Withdrawal would apply are indicated on our website. This will be stated within the confirmation email, which you will receive with your order. In that case, you must inform us within 14 days of receiving your product, via the Right of Withdrawal form, customer care team or by other means.</p>
<h3>Be aware of abuse of your code</h3>
<p>Note that the code represents digital value (a digital good) that can be used immediately. This means that you can start using the code instantly after receipt. So can somebody else, if they have access to the code. To prevent abuse, please keep the code for yourself and do not share it with others.</p>
<h3>Your personal data</h3>
<p>We trust that you have provided us with your correct contact details while ordering. We use your email address and telephone number to provide you with the digital code you have purchased. We also use your email address for maintaining contact with you, which includes sending you promotions, newsletters and other offers that we think might be of interest to you.

We have taken technical and organisational measures to protect your contact details and personal data. If you want to access or change your personal data, please have a look at our Privacy Statement. 

To find out more about how we protect your personal data, have a look at our Privacy Policy.

If you do not want to receive our newsletters and promotions any longer, you can unsubscribe at any moment in time via the unsubscribe link in our newsletters.</p>
<h3>Questions?</h3>
<p>We have tried to make topping up and buying digital credit with us as easy as possible. Do you nevertheless have a question about our services? Our customer service team will be happy to help you. We will try to resolve any question within 2 business days. If we do not succeed within this period, we will notify you. 

Do you have a question about the services of a provider? We advise you to first contact the provider, who will then try to find a solution with you. Does the provider not come up with a solution? As an intermediary we will try to help you further. Please contact our customer service team via our FAQ page.

Do you have a complaint we were not able to help you with? You may always submit your complaint to the European Dispute Commission via https://ec.europa.eu/consumers/odr/. We are prepared to participate in this dispute resolution regulation.</p>
<h3>Any further questions?</h3>
<p>If you have any further questions, you can check our FAQ. If your question is not listed, please contact our customer care team. We will be happy to help you.</p>

                        </Col>
                        </Row>
                        <Row>
                    <Col className="homeFaq" md={8}>
                    <img
        src={sophie}
        alt=""
      />
                    <div>
                        <h6>Need advice or help?</h6>
                        <p>Please check our <a href="/Faq">Frequently Asked Questions.</a></p>
                    </div>
                    </Col>
                </Row>
                        </Container>
            <Footer/>
        </div>
    )
}

export default Conditions
