import React, { useContext, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Tabs,
  Tab,
  Button,
  Form,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { AuthContext } from "./context";

function PayCarrier(props) {
  const user = useContext(AuthContext);
  const [key, setKey] = useState("Fixed");
  const [denomSelect, setDenomSelect] = useState("");
  const [goto, setGoto] = useState(false);
  const verified = user && user.emailVerified;
  const { provider, phone, fixed } = props.location.state;
  const flexi = props.flexi;

  if (!flexi || !fixed) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  const amtStp = flexi.amountStep && flexi.amountStep;
  const amountStep = parseInt(amtStp);
  const products = fixed.products && fixed.products;

  if (goto) {
    return (
      <Redirect
        to={{
          pathname: verified ? "/Cart_flex" : !user ? "/SignIn" : "/Account",
          state: {
            provider,
            phone,
            denomination: denomSelect,
          },
        }}
      />
    );
  }

  return (
    <div>
      <Header />
      <Container fluid className="aboutBanner">
        <h2 className="bannerh2">
          {fixed.operator ? fixed.operator : flexi.operator} : Recharge for
          yourself, friends or family
        </h2>
      </Container>

      <Container>
        <h5 className="text-center mt-4 mb-4">
          Choose your desired product type
        </h5>
        <Row>
          <Col md={8} className="mr-auto ml-auto">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="creditData"
              transition={false}
            >
              <Tab eventKey="Fixed" title="Fixed Denominations">
                <Container className="mt-5">
                  <Row>
                    {products ? (
                      products.map((product, index) => {
                        return (
                          <Col md={3} xs={6} key={index} className="mb-4">
                            <Card className="p-2 MainCard">
                              <Card.Text className="text-center">
                                Receiver Gets:
                                {" " +
                                  product.denomination +
                                  product.denom_Currency}
                              </Card.Text>
                              <h6 className="text-center">
                                {" "}
                                {" " +
                                  product.denom_AfterTax +
                                  product.denom_Currency}
                              </h6>
                              <p className="text-center">
                                Top-up Amount:{" "}
                                <span>
                                  {product.price + product.price_currency}
                                </span>
                              </p>
                              <Link
                                style={{ alignSelf: "center" }}
                                to={{
                                  pathname: verified
                                    ? "/Cart"
                                    : !user
                                    ? "/SignIn"
                                    : "/Account",
                                  state: {
                                    provider,
                                    phone,
                                    denomination: product.denomination,
                                    denomCurrency: product.denom_Currency,
                                    country: fixed.country,
                                    operator: fixed.operator,
                                    price: product.price,
                                    priceCurrency: product.price_currency,
                                  },
                                }}
                              >
                                <Button className="reButton mt-2">
                                  Buy Credits
                                </Button>
                              </Link>
                            </Card>
                          </Col>
                        );
                      })
                    ) : (
                      <Col md={12}>
                        <Card className="p-3 mainCard">
                          <p className="spiColor text-center">
                            "Sorry this Operator does not support Fixed
                            Denominations"
                          </p>
                          <Button
                            variant="primary"
                            className="reButton"
                            onClick={() => setKey("Flexi")}
                          >
                            Check Flexible Denominations
                          </Button>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="Flexi" title="Flexible Denominations">
                <Container className="mt-3">
                  {flexi.operator && (
                    <Row className="mb-3">
                      <Col md={6} xs={6} className="pl-4 pr-1">
                        <Card className="p-2 spiColor text-center">
                          Minimum Denomination Value : {flexi.minValue}
                        </Card>
                      </Col>
                      <Col md={6} xs={6} className="pr-4 pl-1">
                        <Card className="p-2 spiColor text-center">
                          Maximum Denomination Value : {flexi.maxValue}
                        </Card>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12} xs={12} className="mb-4">
                      {flexi.operator ? (
                        <Card className="p-2 MainCard">
                          <Form className="m-3">
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>
                                Recharge Amount in {flexi.denomCurrency}
                              </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="0"
                                onChange={(e) => setDenomSelect(e.target.value)}
                                max={flexi.maxValue}
                                min={flexi.minValue}
                                required
                              />
                              <Form.Text className="text-muted">
                                Please increase amount by {amountStep} no
                                decimals eg
                                {" " +
                                  flexi.minValue +
                                  "+" +
                                  amountStep +
                                  " = "}
                                {parseInt(flexi.minValue) + amountStep}
                              </Form.Text>
                            </Form.Group>

                            <Button
                              variant="primary"
                              type="submit"
                              className="reButton"
                              block
                              onClick={() => denomSelect && setGoto(true)}
                            >
                              Submit
                            </Button>
                          </Form>
                        </Card>
                      ) : (
                        <Card className="p-3 mainCard">
                          <p className="spiColor text-center">
                            "Sorry this Operator does not support Flexible
                            Denominations"
                          </p>
                          <Button
                            variant="primary"
                            className="reButton"
                            onClick={() => setKey("Fixed")}
                          >
                            Check Fixed Denominations
                          </Button>
                        </Card>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
export default PayCarrier;
