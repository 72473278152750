import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import About from "./About";
import Carrier from "./Carrier";
import Account from "./Account";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Axios from "axios";
import Country from "./Country";
import Safety from "./Safety";
import Cart from "./Cart";
import FlexCart from "./FlexCart";
import ProdCart from "./ProdCart";
import Faq from "./Faq";
import Privacy from "./Privacy";
import Cookie from "./Cookie";
import Conditions from "./Conditions";
import SignIn from "./SignIn";
import PayCarrier from "./PayCarrier";
import { firebase } from "./Setup";
import { AuthContext } from "./context";
import NotFound from "./404";
import { Container, Spinner, Row, Col } from "react-bootstrap";
import logo from "./img/logo.png";

function App() {
  const [countries, setCountries] = useState({});
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();
  const [countryCode, setcountryCode] = useState("");
  const [Loaded, setLoaded] = useState(false);
  const [flexi, setFlexi] = useState(null);

  useEffect(() => {
    Axios.get("https://ezetopmobile.com/app/country", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setCountries(response.data);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getGeoInfo = () => {
    Axios.get("https://ipapi.co/json/")
      .then((response) => {
        const data = response.data;
        setcountryCode(data.country);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handle user state changes
  const onAuthStateChanged = (userObject) => {
    setUser(userObject);
    if (initializing) setInitializing(false);
  };

  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  });

  useEffect(() => {
    getGeoInfo();
  }, []);

  if (initializing) return null;

  if (Loaded) {
    return (
      <AuthContext.Provider value={user}>
        <Router>
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Home
                    {...props}
                    countryCode={countryCode}
                    countries={countries}
                    setFlexi={setFlexi}
                    flexi={flexi}
                  />
                )}
              />
              <Route
                exact
                path="/About"
                render={(props) => <About {...props} />}
              />
              <Route
                exact
                path="/Carriers"
                render={(props) => <Carrier {...props} />}
              />
              <Route
                exact
                path="/Countries"
                render={(props) => <Country {...props} Countries={countries} />}
              />
              <Route
                exact
                path="/Safety"
                render={(props) => <Safety {...props} />}
              />
              <Route
                exact
                path="/Account"
                render={(props) => <Account {...props} />}
              />
              <Route exact path="/Faq" render={(props) => <Faq {...props} />} />
              <Route
                exact
                path="/Privacy"
                render={(props) => <Privacy {...props} />}
              />
              <Route
                exact
                path="/Cookies"
                render={(props) => <Cookie {...props} />}
              />
              <Route
                exact
                path="/Conditions"
                render={(props) => <Conditions {...props} />}
              />
              <Route
                exact
                path="/Signin"
                render={(props) => <SignIn {...props} />}
              />
              <Route
                exact
                path="/Products"
                render={(props) => <PayCarrier {...props} flexi={flexi} />}
              />
              <Route
                exact
                path="/Cart"
                render={(props) => <Cart {...props} />}
              />
              <Route
                exact
                path="/Cart_flex"
                render={(props) => <FlexCart {...props} flexi={flexi} />}
              />
              <Route
                exact
                path="/Cart_prod"
                render={(props) => <ProdCart {...props} />}
              />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </Router>
      </AuthContext.Provider>
    );
  } else {
    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <center>
              <img
                src={logo}
                className="d-inline-block align-top mt-5"
                alt="Recharge"
                height="64"
              />
              <br />
              <Spinner animation="grow" className="mt-3 spiColor" />
            </center>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default App;
