import React from 'react'
import {Card, Col, Container, Row} from 'react-bootstrap'
import Footer from './Footer'
import Header from './Header'

function Carrier({Providers}) {

    return (
        <div>
            <Header/>
             <Container fluid className="carrierBanner">
                 <h2 className="bannerh2">Carriers</h2>
            </Container>
            <Container className="mt-5">
<Row>
{Providers && Providers.map((item)=>{
    return( <Col md={2} xs={6} key={item.ProviderCode}>
        <Card className="p-3 pCard">
            <img 
        src={item.LogoUrl}
        alt=""
        />
           </Card>
           <p className="text-center">{item.Name}</p>
           </Col>)
})}
   
    
</Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default Carrier
