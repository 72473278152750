import React, { useEffect } from "react";
import { Container, Row, Col, Button, Accordion, Card } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaLock, FaTicketAlt, FaTv } from "react-icons/fa";
import Banner from "./Banner";
import Header from "./Header";
import Footer from "./Footer";
import homepp from "./img/paypal-tablet-095ce73fea.png";
import homepv from "./img/visa-tablet-e88f2c4d1f.png";
import homepm from "./img/mastercard-tablet-b27ba22caf.png";
import sophie from "./img/sophie-5b75317e49.png";

function Home({ countryCode, countries, flexi, setFlexi }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <Header />
      <Banner
        countryCode={countryCode}
        countries={countries}
        setFlexi={setFlexi}
        flexi={flexi}
      />
      <Container>
        <Row className="homePay mt-4">
          <Col xs={4} md={4}>
            <img alt="Paypal" src={homepp} height="34" width="107" />
          </Col>
          <Col xs={4} md={4}>
            <img alt="Master Card" src={homepm} />
          </Col>
          <Col xs={4} md={4}>
            {" "}
            <img alt="Visa" src={homepv} />
          </Col>
        </Row>

        <Row>
          <Col className="infoBanner mt-4">
            <IconContext.Provider value={{ className: "listIcons" }}>
              <ul>
                <li>
                  <FaTv />
                  Instant digital delivery
                </li>
                <li>
                  <FaLock />
                  Trusted by millions
                </li>
                <li>
                  <FaTicketAlt />
                  Pay safely & securely
                </li>
              </ul>
            </IconContext.Provider>
          </Col>
        </Row>
        <h4>Information about Ezetopmobile.com</h4>
        <Accordion>
          <Card>
            <Card.Header>
              <h6>What is Ezetopmobile.com?</h6>
            </Card.Header>
            <Card.Body>
              No matter how many borders you cross, thanks to Recharge’s fast
              and safe service, you’ll easily stay in touch with one another.
              Recharge yours or
            </Card.Body>
            <Accordion.Collapse eventKey="0">
              <p>
                someone else’s call credit or data 24/7 in over 140 countries.
                Depending on the product you choose, we’ll directly send the
                credit to your phone or as a recharge code by email. We make
                sure you’ll always stay connected.
              </p>
            </Accordion.Collapse>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Read More
            </Accordion.Toggle>
          </Card>

          <Card>
            <Card.Header>
              <h6>How does the mobile recharge work?</h6>
            </Card.Header>
            <Card.Body>
              No matter how many borders you cross, thanks to Recharge’s fast
              and safe service, you’ll easily stay in touch with one another.
              Recharge yours or
            </Card.Body>
            <Accordion.Collapse eventKey="1">
              <ol>
                <li>Choose the country</li>
                <p>Where is the phone you want to refill?</p>
                <li>Select carrier and product</li>
                <p>Let us know which product you’re going to buy</p>
                <li>Fill in your information</li>
                <p>
                  Fill in your email address and/or the recipient’s phone number
                </p>
                <li>Pay and receive</li>
                <p>After your payment the order will be sent immediately!</p>
              </ol>
            </Accordion.Collapse>
            <Accordion.Toggle as={Button} variant="link" eventKey="1">
              Read More
            </Accordion.Toggle>
          </Card>

          <Card>
            <Card.Header>
              <h6>Make safe and secure payments</h6>
            </Card.Header>
            <Card.Body>
              We make sure the information you give is secured
            </Card.Body>
            <Accordion.Collapse eventKey="2">
              <p className="mt-2">
                Privacy and safety are very important to us at Ezetopmobile.com.
                That’s why there’s a <a href="k">Privacy Policy</a> and an
                explanation on <a href="k">how safe Ezetopmobile.com</a> is. You
                can pay online without worrying!
              </p>
            </Accordion.Collapse>
            <Accordion.Toggle as={Button} variant="link" eventKey="2">
              Read More
            </Accordion.Toggle>
          </Card>
        </Accordion>
        <Row>
          <Col className="homeFaq" md={8}>
            <img src={sophie} alt="" />
            <div>
              <h6>Need advice or help?</h6>
              <p>
                Please check our <a href="/Faq">Frequently Asked Questions.</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Home;
